import React, { useState } from "react";
import db, { auth, firebase, storage } from "./firebase";
import { useStateValue } from "./StateProvider";
import { setUser } from "./actions/userAction";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import { toastInfo } from "./shared/toastInfo";

export default function AddUser() {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState("");
  const [{ user }, dispatch] = useStateValue();
  const history = useHistory();
  const [adminData, setAdminData] = useState({});
  const [fileImageUrl, setFileImageUrl] = useState("");

  useEffect(() => {
    if (!user || user?.role !== "admin") {
      history.push("/");
    } else {
      setAdminData(user);
    }
  }, []);

  const signUp = () => {
    auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential) => {
        var user = userCredential.user;
        console.log(user);
        db.collection("users")
          .doc(user.uid)
          .set({
            name: username,
            uid: user.uid,
            email: user.email,
            photoURL: fileImageUrl
              ? fileImageUrl
              : `https://api.dicebear.com/7.x/avataaars/svg?seed=${username}`,
            role: "user",
            password: password,
            timestamp: firebase.firestore.FieldValue.serverTimestamp(),
          })
          .then(() => {
            console.log("finished adding");
            auth
              .signInWithEmailAndPassword(adminData.email, adminData.password)
              .then(() => {
                console.log("finish login");
              });
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
        history.push("/admin");
      })
      .catch((err) => setError(err.message));
  };

  const onFileChange = async (e) => {
    const fileSizeToastId = "fileSizeToastId";
    const file = e.target.files[0];
    console.log(file);
    if (file.size > 12 * 1024 * 1024) {
      toastInfo(
        "File should not exceed more than 12MB",
        fileSizeToastId,
        "top-center"
      );
    } else {
      const storageRef = storage.ref();
      if (file.type.match("image.*")) {
        const imagesRef = storageRef.child(`images/`);
        const fileRef = imagesRef.child(new Date().getTime() + " " + file.name);
        await fileRef.put(file);
        setFileImageUrl(await fileRef.getDownloadURL());
      }
    }
  };
  return (
    <div className="login">
      <h2>Add New User</h2>

      <input
        type="text"
        name="username"
        required
        onChange={(e) => setUsername(e.target.value)}
        placeholder="Username"
      />
      <input
        type="email"
        name="email"
        required
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        name="password"
        required
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      <input
        id="file-input"
        type="file"
        onChange={onFileChange}
        accept="image/*"
      />
      <div>Current Image:</div>
      <img
        src={
          fileImageUrl
            ? fileImageUrl
            : `https://api.dicebear.com/7.x/avataaars/svg?seed=${username}`
        }
        width={100}
        height={100}
        alt=""
      />

      {error ? <p>{error}</p> : null}
      <button onClick={signUp}>Sign Up</button>
    </div>
  );
}
