import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import db, { auth } from "./firebase";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Box, Button } from "@material-ui/core";
import { useStateValue } from "./StateProvider";

export default function Admin() {
  const [{ user }, dispatch] = useStateValue();
  const [adminData, setAdminData] = useState({});

  const history = useHistory();
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (!user || user?.role !== "admin") {
      history.push("/");
    } else {
      setAdminData(user);
    }

    db.collection("users")
      .get()
      .then((querySnapshot) => {
        const docs = querySnapshot.docs.map((doc) => doc.data());
        setUserData(docs);
      });
  }, []);

  const columns = [
    { field: "uid", headerName: "ID", flex: 1 },
    { field: "name", headerName: "Name", flex: 1 },
    { field: "email", headerName: "Email", flex: 1 },
    {
      field: "photoURL",
      headerName: "Image",
      width: 65,
      editable: true,
      renderCell: (params) => (
        <img
          style={{ width: "100%", maxHeight: "150px", objectFit: "contain" }}
          src={params.value}
          alt=""
        />
      ), // renderCell will render the component
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      disableClickEventBubbling: true,

      renderCell: (params) => {
        const onClick = (e) => {
          const currentRow = params.row;
          console.log(currentRow);
        };

        const handleDelete = async () => {
          const currentRow = params.row;

          if (currentRow.role === "admin") {
            return;
          }
          db.collection("users")
            .doc(currentRow.uid)
            .delete()
            .then(() => {
              console.log("deleting from firestore");
            })
            .catch(function (error) {
              console.error("Error adding document: ", error);
            });

          auth
            .signInWithEmailAndPassword(currentRow.email, currentRow.password)
            .then((userCredential) => {
              var u = userCredential.user;
              console.log(u);
              u.delete()
                .then(() => {
                  console.log("User account deleted successfully!");
                  auth.signOut();
                  console.log("Logout!");

                  auth.signInWithEmailAndPassword(
                    adminData.email,
                    adminData.password
                  );
                })
                .catch((error) => {
                  console.error("Error deleting user account:", error);
                });
            })
            .catch((err) => console.log("Invalid Login Credentials"));
        };

        return (
          <>
            {/* <ConfirmDialog
              title="Delete Post?"
              open={confirmOpen}
              setOpen={setConfirmOpen}
              onConfirm={handleDelete}
            >
              Are you sure you want to delete this post?
            </ConfirmDialog> */}

            <Button
              variant="outlined"
              color="secondary"
              size="small"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div className="admin">
      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>All Users</h1>
        <div>
          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={() => {
              history.push("/adduser");
            }}
          >
            Add User
          </Button>
        </div>
      </div>
      <Box
        width="100%"
        // m="15px 0 0 0"
        height="80%"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
            color: "#fff",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: "#212621",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#212621",
            // borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#212621",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "#212621",
          },
          "& .MuiCheckbox-root": {
            color: `#fff !important`,
          },
          "& .MuiInputBase-root": {
            color: `#fff !important`,
          },
          "& .MuiListItemText-root": {
            color: `#fff !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `#fff !important`,
          },
        }}
      >
        <DataGrid
          rows={userData}
          columns={columns}
          // components={{ Toolbar: GridToolbar }}
          getRowId={(row) => row.uid}
        />
      </Box>
    </div>
  );
}
