import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCMsB_9Tne91sCE4xOSD18AK_T7X_BmCdM",
  authDomain: "westcoasterbc-chat-app.firebaseapp.com",
  projectId: "westcoasterbc-chat-app",
  storageBucket: "westcoasterbc-chat-app.appspot.com",
  messagingSenderId: "351663863181",
  appId: "1:351663863181:web:9bf91cad162e5ef9d579c4",
  measurementId: "G-MEASUREMENT_ID",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

export { auth, provider, storage, firebase };
export default db;
