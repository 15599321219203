import React, { useEffect, useRef, useState } from "react";
import { useStateValue } from "../StateProvider";
import { toastInfo } from "../shared/toastInfo";
//importing components
import { Picker } from "emoji-mart";
import TooltipCustom from "../shared/TooltipCustom";
import DrawerBottom from "./DrawerBottom";
//importing material-ui
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fab from "@material-ui/core/Fab";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
//importing material-ui-icons
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CloseIcon from "@material-ui/icons/Close";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import PersonIcon from "@material-ui/icons/Person";
import PhotoIcon from "@material-ui/icons/Photo";
import SendIcon from "@material-ui/icons/Send";
import VideoCallIcon from "@material-ui/icons/VideoCall";
//importing styles
import "emoji-mart/css/emoji-mart.css";
import "./ChatFooter.css";
import {
  MicNoneOutlined,
  RadioButtonCheckedOutlined,
} from "@material-ui/icons";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { v4 as uuidv4 } from "uuid";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";

// import { TwitterVideoEmbed } from "react-twitter-embed";

const attachFileLists = [
  {
    title: "Room",
    icon: <VideoCallIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Contact",
    icon: <PersonIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Document",
    icon: <InsertDriveFileIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Camera",
    icon: <CameraAltIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Photos & Videos",
    icon: <PhotoIcon />,
    id: Math.random() * 100000,
  },
];

function ChatFooter({ db, firebase, storage, isPreview, setIsPreview }) {
  const [{ user }] = useStateValue();
  const [input, setInput] = useState("");
  const [emoji, setEmoji] = useState(false);
  const [fileImageUrl, setFileImageUrl] = useState(null);
  const [fileVideoUrl, setFileVideoUrl] = useState(null);
  const [fileAudioUrl, setFileAudioUrl] = useState(null);
  const [drawerBottom, setDrawerBottom] = useState(false);
  const [showAttachFile, setShowAttachFile] = useState(false);
  const [showVoiceFile, setShowVoiceFile] = useState(false);
  const [showVoiceFileCancel, setShowVoiceFileCancel] = useState(false);
  const fileInput = useRef(null);

  const [link, setLink] = useState("");
  const [extra, setExtra] = useState("");

  const addData = async (link) => {
    if (link) {
      await db
        .collection("messages")
        .add({
          message: "",
          extra: extra,
          video: link,
          name: user.name,
          uid: user.uid,
          photoURL: user.photoUrl,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          db.collection("messages").doc(docRef.id).set(
            {
              id: docRef.id,
            },
            { merge: true }
          );
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
    setInput("");
  };

  useEffect(() => {
    addData(link);
  }, [link, extra]);

  const checkLink = (regex, input) => {
    let arr = input.split(" ");
    let check = false;
    if (arr.length > 1) {
      setExtra(input);
    }

    for (let i = 0; i < arr.length; i++) {
      if (regex.test(arr[i])) {
        check = true;
        setLink(arr[i]);
        break;
      }
    }
    return check;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    const youtubeLink =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const facebookVideoLink =
      /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
    const vimeoLink =
      /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    const soundcloudLink = /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/;
    const dailymotionLink =
      /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/;
    const urlLink =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const twitterLink =
      /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;

    if (
      checkLink(youtubeLink, input) ||
      checkLink(facebookVideoLink, input) ||
      checkLink(vimeoLink, input) ||
      checkLink(soundcloudLink, input) ||
      checkLink(dailymotionLink, input) ||
      checkLink(twitterLink, input)
    ) {
      addData(link);
      // if (link) {
      // db.collection("rooms")
      //   .doc(roomId)
      //   .collection("messages")
      //   .add({
      //     message: "",
      //     video: link,
      //
      //
      //     name: user.name,
      // uid: user.uid,
      // photoURL: user.photoUrl,
      //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      //   })
      //   .then(function (docRef) {
      //     console.log("here");
      //     console.log(link);
      //     console.log(result);
      //     console.log("Document written with ID: ", docRef.id);
      //     db.collection("rooms")
      //       .doc(roomId)
      //       .collection("messages")
      //       .doc(docRef.id)
      //       .set(
      //         {
      //           id: docRef.id,
      //         },
      //         { merge: true }
      //       );
      //   })
      //   .catch(function (error) {
      //     console.error("Error adding document: ", error);
      //   });

      // console.log("YOUTUBE LINK VALID");
    } else if (urlLink.test(input)) {
      await db
        .collection("messages")
        .add({
          message: "",
          url: input,
          name: user.name,
          uid: user.uid,
          photoURL: user.photoUrl,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          console.log(link);

          console.log("Document written with ID: ", docRef.id);
          db.collection("messages").doc(docRef.id).set(
            {
              id: docRef.id,
            },
            { merge: true }
          );
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    } else if (/\S/.test(input)) {
      await db
        .collection("messages")
        .add({
          message: input,
          name: user.name,
          uid: user.uid,
          photoURL: user.photoUrl,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          // console.log("Document written with ID: ", docRef.id);
          db.collection("messages").doc(docRef.id).set(
            {
              id: docRef.id,
            },
            { merge: true }
          );
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
    // }
    setInput("");
    setEmoji(false);
  };

  const attachFile = () => {
    // if (showAttachFile === false) {
    //   setShowAttachFile(true);
    // } else {
    //   setShowAttachFile(false);
    // }
    fileInput.current.click();
  };

  const voiceMessage = () => {
    if (showVoiceFile === false) {
      setShowVoiceFile(true);
      recorderControls.startRecording();
      console.log("recording");
    } else {
      setShowVoiceFile(false);
      setShowVoiceFileCancel(false);
      recorderControls.stopRecording();
      console.log("stop recording");
    }
  };

  const cancelVoiceMessage = () => {
    setShowVoiceFile(false);
    setShowVoiceFileCancel(true);
    recorderControls.stopRecording();
  };

  const addEmoji = (e) => {
    let emoji = e.native;
    setInput(input + emoji);
  };

  const handleEmoticons = () => {
    setEmoji(true);
  };

  const handleEmoticonsClose = () => {
    setEmoji(false);
  };

  const onFileChange = async (e) => {
    const fileSizeToastId = "fileSizeToastId";
    const file = e.target.files[0];
    console.log(file);
    if (file.size > 12 * 1024 * 1024) {
      toastInfo(
        "File should not exceed more than 12MB",
        fileSizeToastId,
        "top-center"
      );
    } else {
      const storageRef = storage.ref();
      if (file.type.match("image.*")) {
        const imagesRef = storageRef.child(`images/`);
        const fileRef = imagesRef.child(new Date().getTime() + " " + file.name);
        await fileRef.put(file);
        setFileImageUrl(await fileRef.getDownloadURL());

        console.log("uploading image", fileImageUrl);
      } else if (file.type.match("video.*")) {
        const videosRef = storageRef.child(`videos/`);
        const fileRef = videosRef.child(new Date().getTime() + " " + file.name);
        await fileRef.put(file);
        setFileVideoUrl(await fileRef.getDownloadURL());
        console.log("uploading video", fileVideoUrl);
      } else if (file.type.match("audio.*")) {
        const audiosRef = storageRef.child(`audio/`);
        const fileRef = audiosRef.child(new Date().getTime() + " " + file.name);
        await fileRef.put(file);
        setFileAudioUrl(await fileRef.getDownloadURL());
        console.log("uploading video", fileAudioUrl);
      }
      setDrawerBottom(true);
      setIsPreview(true);
    }
  };

  const handleClickAway = () => {
    setShowAttachFile(false);
  };

  const recorderControls = useAudioRecorder();
  const addAudioElement = async (blob) => {
    const storageRef = storage.ref();
    const file = new File(
      [blob],
      new Date().getTime() + " " + uuidv4() + ".mp3",
      {
        type: "audio/mpeg",
      }
    );
    console.log(file);
    const audiosRef = storageRef.child(`audio/`);
    const fileRef = audiosRef.child(new Date().getTime() + " " + file.name);
    await fileRef.put(file);
    let url = await fileRef.getDownloadURL();

    db.collection("messages")
      .add({
        audio: url,
        name: user.name,
        uid: user.uid,
        photoURL: user.photoUrl,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then(function (docRef) {
        db.collection("messages").doc(docRef.id).set(
          {
            id: docRef.id,
          },
          { merge: true }
        );
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
    setFileAudioUrl(null);
    // setDrawerBottom(true);
  };

  return (
    <div className="chat__footer">
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={showVoiceFileCancel ? () => {} : recorderControls}
        downloadFileExtension="mp3"
        mediaRecorderOptions={{
          mimeType: "audio/mpeg",
        }}
        // audioTrackConstraints={{
        //   noiseSuppression: true,
        //   echoCancellation: true,
        // }}
      />

      <DrawerBottom
        drawerBottom={drawerBottom}
        setDrawerBottom={setDrawerBottom}
        fileVideoUrl={fileVideoUrl}
        fileImageUrl={fileImageUrl}
        fileAudioUrl={fileAudioUrl}
        setFileImageUrl={setFileImageUrl}
        setFileVideoUrl={setFileVideoUrl}
        setFileAudioUrl={setFileAudioUrl}
        db={db}
        firebase={firebase}
        storage={storage}
        setIsPreview={setIsPreview}
      />

      {emoji ? (
        <TooltipCustom
          name="Close"
          icon={<CloseIcon />}
          onClick={handleEmoticonsClose}
        />
      ) : (
        <TooltipCustom
          name="Emoji"
          icon={<InsertEmoticonIcon />}
          onClick={() => handleEmoticons()}
        />
      )}

      {emoji ? (
        <>
          <Hidden only={["xs"]}>
            <Picker onSelect={addEmoji} />
          </Hidden>
          <Hidden smUp>
            <ClickAwayListener onClickAway={() => {}}>
              <Picker onSelect={addEmoji} />
            </ClickAwayListener>
          </Hidden>
        </>
      ) : null}

      <>
        <TooltipCustom
          name="Attach"
          icon={<AttachFileOutlinedIcon />}
          onClick={attachFile}
        />
        <input
          hidden
          ref={fileInput}
          id="file-input"
          type="file"
          onChange={onFileChange}
          accept="image/*,video/mp4,video/3gpp,video/quicktime,audio/*"
        />
      </>

      {showVoiceFile ? (
        <TooltipCustom
          name="Stop"
          icon={<CloseIcon />}
          onClick={cancelVoiceMessage}
        />
      ) : (
        <TooltipCustom
          name="Voice"
          icon={<MicNoneOutlined />}
          onClick={voiceMessage}
        />
      )}

      {showVoiceFile ? (
        <div
          style={{
            display: "flex",
            flex: 1,
            gap: "10px",
            flexDirection: "row",
            paddingLeft: "20px",
          }}
        >
          <RadioButtonCheckedOutlined style={{ fill: "red" }} />
          <h3 style={{ color: "red" }}>Recording....</h3>
        </div>
      ) : (
        <form>
          <input
            required
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type a message"
            type="text"
          />
          <button onClick={sendMessage} type="submit">
            Send a message
          </button>
        </form>
      )}

      <TooltipCustom
        name="Send Message"
        icon={<SendIcon style={{ color: "#28d146" }} />}
        onClick={showVoiceFile ? voiceMessage : sendMessage}
      />
      <div></div>
    </div>
  );
}

export default ChatFooter;
