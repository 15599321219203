import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
//importing component
import UserAvatar from "../Sidebar/UserAvatar";
import DialogCustom from "../shared/DialogCustom";
//importing material-ui-icon
import AlarmIcon from "@material-ui/icons/Alarm";
import DoneIcon from "@material-ui/icons/Done";
//importing styles
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import { TwitterVideoEmbed } from "react-twitter-embed";
import TooltipCustom from "../shared/TooltipCustom";
import "./ChatBody.css";
import Bowser from "bowser";

// import { useLongPress } from "use-long-press";

function ChatBody({
  messages,
  user,
  isRoomExist,
  selectedMessage,
  setSelectedMessage,
  setIsPin,
  isPreview,
}) {
  const messagesEndRef = useRef(null);
  // const { roomId } = useParams();
  const [playing, setPlaying] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [image, setImage] = useState("");
  const [browser, setBrowser] = useState({});

  useEffect(() => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    setBrowser(browser.getBrowser());
    const timer = setTimeout(() => {
      scrollToBottom();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    //listens when room is changed, then it sets playing to false
    //so timestamp in ReactPlayer will display again
    if (isRoomExist >= 0) {
      setPlaying(false);
    }
  }, [isRoomExist]);

  const handlePlay = () => {
    setPlaying(true);
  };

  const handlePause = () => {
    setPlaying(false);
  };

  const handleDialogOpen = (image) => {
    setImage(image);
    setShowDialog(true);
  };

  const handleDialogClose = () => {
    setShowDialog(false);
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(scrollToBottom, [messages]);

  const handleClick = (e, id, isPin) => {
    if (e.type === "click") {
      console.log("Left click");
    } else if (e.type === "contextmenu") {
      console.log("Right click");
      setSelectedMessage(id);
      setIsPin(isPin);
    }
  };

  return (
    <div>
      {!isPreview && (
        <div className="scroll-down">
          <TooltipCustom
            name="Scroll Down"
            icon={<ExpandMoreOutlinedIcon color="#fff" />}
            onClick={scrollToBottom}
          />
        </div>
      )}
      {messages.length !== 0 &&
        messages.map((message) => (
          <div className="chat__container" key={message.id}>
            <div
              className={`chat__profile ${
                message.uid === user.uid && "chat__profile_sender"
              }`}
            >
              <UserAvatar photoURL={message.photoURL} />
            </div>
            <div
              key={message.id}
              id={message.id}
              onClick={(e) => {
                if (user.role === "admin") {
                  handleClick(e, message.id, message.pin === true);
                }
              }}
              onContextMenu={(e) => {
                if (user.role === "admin") {
                  handleClick(e, message.id, message.pin === true);
                }
              }}
              className={`chat__message 
                    ${message.uid === user.uid && "chat__receiver"} 
                    ${message.photo && "chat__message_media_image"}
                    ${message.video && "chat__message_media_video"}
                    ${message.audio && "chat__message_media_audio"}
                    ${
                      message.audio &&
                      browser?.name === "Safari" &&
                      "chat__message_media_audio_safari_container"
                    }
                    ${
                      message.video &&
                      !message.caption &&
                      "chat__message_media_video_noCaption"
                    } 
                    `}
            >
              <span
                className={`chat__name ${
                  message.uid === user.uid && "chat__name_sender"
                }`}
              >
                {message.name}
              </span>

              <div className="chat__body_image_container">
                {message.photo ? (
                  <>
                    <img
                      alt=""
                      className="chat__body_image"
                      src={message.photo}
                      onClick={() => handleDialogOpen(message.photo)}
                    />

                    <DialogCustom
                      open={showDialog}
                      close={handleDialogClose}
                      photo={image}
                      user={message}
                    />
                  </>
                ) : null}
              </div>

              <div className="chat__body_video_container">
                {message.video ? (
                  message.video.includes("twitter") ? (
                    <div className="player-twitter">
                      {message.extra ? (
                        <a
                          target="_blank"
                          href={`${message.video}`}
                          rel="noopener noreferrer"
                        >
                          {message.extra}
                        </a>
                      ) : (
                        <a
                          target="_blank"
                          href={`${message.video}`}
                          rel="noopener noreferrer"
                        >
                          {message.video}
                        </a>
                      )}

                      <TwitterVideoEmbed
                        key={message.video}
                        id={
                          message.video.includes("http")
                            ? message.video.split("/")[5]
                            : message.video.split("/")[4]
                        }
                        placeholder="Loading"
                        backgroundColor="black"
                      />
                    </div>
                  ) : (
                    <>
                      {message.extra ? (
                        <a
                          target="_blank"
                          href={`${message.video}`}
                          rel="noopener noreferrer"
                        >
                          {message.extra}
                        </a>
                      ) : message.video.includes(
                          "https://firebasestorage.googleapis.com"
                        ) ? null : (
                        <a
                          target="_blank"
                          href={`${message.video}`}
                          rel="noopener noreferrer"
                        >
                          {message.video}
                        </a>
                      )}
                      <div className="player-wrapper">
                        <ReactPlayer
                          // className="react-player"
                          className={`react-player
                        ${
                          message.video &&
                          message.caption &&
                          "react-player-caption"
                        } `}
                          width="100%"
                          height="100%"
                          url={message.video}
                          controls={true}
                          // onPlay={handlePlay}
                          // onPause={handlePause}
                          // onEnded={handlePause}
                          playsinline={true}
                        />
                        {/* <video controls>
                          <source src={message.video} />
                        </video> */}
                      </div>
                    </>
                  )
                ) : null}
              </div>

              {/* <div className="chat__body_video_container"> */}
              {message.audio ? (
                <>
                  {message.extra ? (
                    <a
                      target="_blank"
                      href={`${message.audio}`}
                      rel="noopener noreferrer"
                    >
                      {message.extra}
                    </a>
                  ) : message.audio.includes(
                      "https://firebasestorage.googleapis.com"
                    ) ? null : (
                    <a
                      target="_blank"
                      href={`${message.audio}`}
                      rel="noopener noreferrer"
                    >
                      {message.audio}
                    </a>
                  )}

                  <div className="player-wrapper-audio">
                    <ReactPlayer
                      // className="react-player"
                      className={`react-player
                        ${
                          message.audio &&
                          message.caption &&
                          "react-player-caption"
                        } `}
                      width="100%"
                      height="100%"
                      url={`${message.audio}`}
                      controls={true}
                      // onPlay={handlePlay}
                      // onPause={handlePause}
                      // onEnded={handlePause}
                      playsinline={true}
                    />
                  </div>
                </>
              ) : null}
              {/* </div> */}

              <div
                // className="chat__message_box"
                className={`chat__message_box ${
                  message.video && !message.caption && "chat__message_box_video"
                }`}
              >
                <div
                  //  className="chat__message_box_text"
                  className={`chat__message_box_text ${
                    message.video &&
                    // !message.caption &&
                    "chat__message_box_text_video"
                  } 
                  ${
                    message.photo &&
                    // !message.caption &&
                    "chat__message_box_text_video"
                  } 
                  ${
                    message.caption === "" &&
                    // !message.caption &&
                    "chat__message_box_text_no_caption"
                  } 
                  `}
                >
                  {message.message ? message.message : null}
                  {message.caption ? message.caption : null}
                  {message.url ? (
                    <a
                      target="_blank"
                      href={`${message.url}`}
                      rel="noopener noreferrer"
                    >
                      {message.url}
                    </a>
                  ) : null}
                </div>

                <div
                  className={`chat__timestamp_container ${
                    message.uid === user.uid
                      ? message.video || message.photo
                        ? !message.caption &&
                          "chat__timestamp_container_sender_video"
                        : "chat__timestamp_container_sender"
                      : !message.caption && "chat__timestamp_container_video"
                  }`}
                >
                  {message.timestamp ? (
                    <div
                      className={`chat__timestamp 
                                    ${
                                      message.photo &&
                                      !message.caption &&
                                      "chat__timestamp_media_photo"
                                    }  
                                    ${
                                      message.video &&
                                      !message.caption &&
                                      "chat__timestamp_media_video"
                                    }
                                    ${
                                      message.video &&
                                      !message.caption &&
                                      playing === true &&
                                      "chat__timestamp_media_displayNone"
                                    }
                                    ${
                                      message.audio &&
                                      "chat__timestamp_media_audio"
                                    }
                                    ${
                                      message.audio &&
                                      browser?.name === "Safari" &&
                                      "chat__message_media_audio_safari"
                                    }
                                    `}
                    >
                      {message.pin && (
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#b4b4b4"
                          style={{ marginRight: "2px" }}
                        >
                          <path d="M4 2h7v.278c0 .406-.086.778-.258 1.117-.172.339-.42.63-.742.875v2.86c.307.145.583.328.828.546.245.219.456.464.633.735.177.27.31.565.398.882.089.318.136.646.141.985v.5H8V14l-.5 1-.5-1v-3.222H3v-.5c0-.339.047-.664.14-.977.094-.312.227-.607.4-.883A3.404 3.404 0 0 1 5 7.13V4.27a2.561 2.561 0 0 1-.734-.875A2.505 2.505 0 0 1 4 2.278V2zm1.086.778c.042.125.094.232.156.32a1.494 1.494 0 0 0 .461.43L6 3.715v4.102l-.336.117c-.411.146-.76.383-1.047.711C4.331 8.973 4.09 9.573 4 10h7c-.088-.427-.33-1.027-.617-1.355a2.456 2.456 0 0 0-1.047-.71L9 7.816V3.715l.297-.18c.094-.057.177-.122.25-.195a2.28 2.28 0 0 0 .21-.242.968.968 0 0 0 .157-.32H5.086z" />
                        </svg>
                      )}

                      <span>
                        {new Date(
                          message.timestamp.toDate()
                        ).toLocaleTimeString("en-US", {
                          hour: "numeric",
                          hour12: true,
                          minute: "numeric",
                        })}
                        {message.uid === user.uid ? <DoneIcon /> : null}
                      </span>
                    </div>
                  ) : (
                    <div className="chat__timestamp">
                      {message.pin && (
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 16 16"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#b4b4b4"
                          style={{ marginRight: "2px" }}
                        >
                          <path d="M4 2h7v.278c0 .406-.086.778-.258 1.117-.172.339-.42.63-.742.875v2.86c.307.145.583.328.828.546.245.219.456.464.633.735.177.27.31.565.398.882.089.318.136.646.141.985v.5H8V14l-.5 1-.5-1v-3.222H3v-.5c0-.339.047-.664.14-.977.094-.312.227-.607.4-.883A3.404 3.404 0 0 1 5 7.13V4.27a2.561 2.561 0 0 1-.734-.875A2.505 2.505 0 0 1 4 2.278V2zm1.086.778c.042.125.094.232.156.32a1.494 1.494 0 0 0 .461.43L6 3.715v4.102l-.336.117c-.411.146-.76.383-1.047.711C4.331 8.973 4.09 9.573 4 10h7c-.088-.427-.33-1.027-.617-1.355a2.456 2.456 0 0 0-1.047-.71L9 7.816V3.715l.297-.18c.094-.057.177-.122.25-.195a2.28 2.28 0 0 0 .21-.242.968.968 0 0 0 .157-.32H5.086z" />
                        </svg>
                      )}

                      <span>
                        {new Date().toLocaleTimeString("en-US", {
                          hour: "numeric",
                          hour12: true,
                          minute: "numeric",
                        })}
                        {message.uid === user.uid ? <AlarmIcon /> : null}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      {/* it will automatically scroll drown everytime the user enters new chat message */}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ChatBody;
