import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useStateValue } from "../StateProvider";
//importing firebase
import db, { firebase, storage } from "../firebase";
//importing components
import ChatBody from "./ChatBody";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
//importing material-ui
import CircularProgress from "@material-ui/core/CircularProgress";
//importing styles
import "react-toastify/dist/ReactToastify.css";
import "./Chat.css";

function Chat({ isRoomExist }) {
  const history = useHistory();
  const [{ user }] = useStateValue();
  const { roomId } = useParams();
  const [messages, setMessages] = useState([]);
  const [pinMessages, setPinMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [isPin, setIsPin] = useState(false);
  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    db.collection("messages")
      .orderBy("timestamp", "asc")
      .onSnapshot(function (doc) {
        let pin = [];

        setMessages(doc.docs.map((doc) => doc.data()));
        doc.docs.map((doc) => {
          if (doc.data()["pin"] === true) {
            pin.push(doc.data());
          }
        });
        setPinMessages(pin);
        setLoading(true);
      });
  }, [history]);
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setIsPreview(true);
    } else {
      setIsPreview(false);
    }
  };

  return (
    <div className="chat">
      <>
        <div>
          <ChatHeader
            messages={messages}
            db={db}
            history={history}
            selectedMessage={selectedMessage}
            setSelectedMessage={setSelectedMessage}
            pinMessages={pinMessages}
            setPinMessages={setPinMessages}
            isPin={isPin}
          />
        </div>

        <div className="chat__body" onScroll={handleScroll}>
          {loading ? (
            <ChatBody
              roomId={roomId}
              messages={messages}
              user={user}
              isRoomExist={isRoomExist}
              selectedMessage={selectedMessage}
              setSelectedMessage={setSelectedMessage}
              setIsPin={setIsPin}
              isPreview={isPreview}
            />
          ) : (
            <div className="chat__body_loading">
              <div>
                <CircularProgress />
              </div>
            </div>
          )}
        </div>

        <>
          <ChatFooter
            roomId={roomId}
            db={db}
            firebase={firebase}
            storage={storage}
            isPreview={isPreview}
            setIsPreview={setIsPreview}
          />
        </>
      </>
    </div>
  );
}

export default Chat;
