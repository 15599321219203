import React, { useEffect, useState } from "react";
import { useStateValue } from "../StateProvider";
//importing components
import TooltipCustom from "../shared/TooltipCustom";
//importing material-ui
import Hidden from "@material-ui/core/Hidden";
//importing material-ui-icons
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";

//importing styles
import "./ChatHeader.css";
import DrawerRight from "./DrawerRight";
import { CheckOutlined, ExitToAppOutlined } from "@material-ui/icons";
import { auth, storage } from "../firebase";
import DropdownMenu from "../shared/DropdownMenu";
import { Avatar } from "@material-ui/core";
import { toastInfo } from "../shared/toastInfo";
import { setUser } from "../actions/userAction";
import { useRef } from "react";

function ChatHeader({
  messages,
  db,
  history,
  selectedMessage,
  setSelectedMessage,
  pinMessages,
  setPinMessages,
  isPin,
}) {
  const [{ user }, dispatch] = useStateValue();
  const [drawerRight, setDrawerRight] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [isLastMessage, setIsLastMessage] = useState(false);
  const [pinMessageIndex, setPinMessageIndex] = useState(0);
  const [isPinCaption, setIsPinCaption] = useState(false);
  const [pinCaption, setPinCaption] = useState("");
  const [menuChat, setMenuChat] = useState(null);
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const fileInput = useRef(null);

  useEffect(() => {
    if (pinMessages.length !== 0) {
      setPinMessageIndex(pinMessages.length - 1);
    }
  }, [pinMessages]);

  useEffect(() => {
    if (messages[messages.length - 1]?.timestamp) {
      setShowDate(true);
    } else {
      setShowDate(false);
    }

    if (messages[messages.length - 1]) {
      setIsLastMessage(true);
    } else {
      setIsLastMessage(false);
    }
  }, [user.uid, user.displayName, user.isAnonymous, db, messages]);

  const getDateFromMessage = () => {
    return new Date(
      messages[messages.length - 1]?.timestamp?.toDate()
    ).toLocaleTimeString([], {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  };

  const getDateLocal = () => {
    return new Date().toLocaleTimeString([], {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      hour12: true,
      minute: "numeric",
    });
  };

  const searchMessage = () => {
    setDrawerRight(true);
  };

  const scrollToMessage = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const changePinMessage = () => {
    if (pinMessageIndex === 0) {
      setPinMessageIndex(pinMessages.length - 1);
    } else {
      setPinMessageIndex(pinMessageIndex - 1);
    }
  };

  const pinMessage = async () => {
    db.collection("messages")
      .doc(selectedMessage)
      .set(
        {
          pin: true,
          pinCaption: pinCaption,
        },
        { merge: true }
      )
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
    setPinMessageIndex(pinMessages.length - 1);
    setIsPinCaption(false);
    setPinCaption("");
    setSelectedMessage("");
  };

  const unpinMessage = async () => {
    db.collection("messages")
      .doc(selectedMessage)
      .set(
        {
          pin: false,
        },
        { merge: true }
      )

      .catch(function (error) {
        console.error("Error changing document: ", error);
      });
    setSelectedMessage("");
  };

  const deleteMessage = async () => {
    db.collection("messages")
      .doc(selectedMessage)
      .delete()
      .catch(function (error) {
        console.error("Error deleting document: ", error);
      });
    setSelectedMessage("");
  };

  const handleMenuClose = () => {
    setMenuChat(null);
  };

  const handleMenuOpen = (event) => {
    setMenuChat(event.currentTarget);
  };

  const menuChatLists = [
    {
      title: "Change Profile Picture",
      onClick: () => fileInput.current.click(),
      id: Math.random() * 100000,
    },
    {
      title: "Log Out",
      onClick: () => auth.signOut(),
      id: Math.random() * 100000,
    },
  ];

  const onProfileChange = async (e) => {
    const fileSizeToastId = "fileSizeToastId";
    const file = e.target.files[0];
    console.log(file);
    if (file.size > 12 * 1024 * 1024) {
      toastInfo(
        "File should not exceed more than 12MB",
        fileSizeToastId,
        "top-center"
      );
    } else {
      const storageRef = storage.ref();
      if (file.type.match("image.*")) {
        const imagesRef = storageRef.child(`images/`);
        const fileRef = imagesRef.child(new Date().getTime() + " " + file.name);
        await fileRef.put(file);
        let url = await fileRef.getDownloadURL();
        setProfileImageUrl(url);
        dispatch(
          setUser({
            name: user.name,
            uid: user.uid,
            role: user.role,
            email: user.email,
            password: user.password,
            photoUrl: url,
          })
        );
        db.collection("users").doc(user.uid).update({
          photoURL: url,
        });
      }
    }
  };

  return (
    <>
      <div className="chat__header">
        <DrawerRight
          drawerRight={drawerRight}
          setDrawerRight={setDrawerRight}
          messages={messages}
          scrollToMessage={scrollToMessage}
          db={db}
          user={user}
        />
        <input
          hidden
          ref={fileInput}
          id="file-input"
          type="file"
          onChange={onProfileChange}
          accept="image/*"
        />

        <div className="chat__headerInfo">
          <h3 style={{ color: "white" }}>DTLA CHAT</h3>
          <Hidden>
            {isLastMessage ? (
              <>
                {showDate ? (
                  <p style={{ color: "#8899a6" }}>
                    Last seen {getDateFromMessage()}
                  </p>
                ) : (
                  <p>Last seen {getDateLocal()}</p>
                )}
              </>
            ) : null}
          </Hidden>
        </div>

        <div className="chat__headerRight">
          <TooltipCustom
            name="Search"
            icon={<SearchOutlinedIcon />}
            onClick={searchMessage}
          />
          <Avatar
            style={{
              width: "45px",
              height: "45px",
            }}
            src={user.photoUrl}
            onClick={handleMenuOpen}
          />
          <DropdownMenu
            menuLists={menuChatLists}
            menu={menuChat}
            handleMenuOpen={handleMenuOpen}
            handleMenuClose={handleMenuClose}
          />
        </div>
      </div>
      {selectedMessage && (
        <div className="chat__header" style={{ cursor: "pointer", zIndex: 9 }}>
          {isPinCaption ? (
            <div className="pin_caption">
              <input
                type="text"
                placeholder="Pin Caption"
                onChange={(e) => setPinCaption(e.target.value)}
              />
              <CheckOutlined
                onClick={pinMessage}
                color="#28d146"
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : (
            <div className="menu_header">
              <div className="menu_header_content">
                {isPin ? (
                  <svg
                    onClick={unpinMessage}
                    width="23"
                    height="23"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#b4b4b4"
                    style={{ cursor: "pointer" }}
                  >
                    <path d="M20 14.274V15h-7v7l-.5 1-.5-1v-6.172L13.828 14h5.163c-.051-.65-.332-.856-1.333-1.515L16.93 12h-1.1l1.16-1.161.927.618c1.302.868 2.084 1.252 2.084 2.817zm2.4-10.966L3.307 22.399l-.707-.707L9.293 15H5v-.726c0-1.565.782-1.95 2.084-2.817l1.147-.765L10 4l-1.522-.43A2.029 2.029 0 0 1 7 1.619V1h11v.618a2.029 2.029 0 0 1-1.478 1.953L15 4l1.107 4.186L21.692 2.6zM10.137 3h4.724l1.388-.392A1.033 1.033 0 0 0 16.926 2H8.074a1.033 1.033 0 0 0 .676.608zm-.954 8h4.109l1.995-1.995L13.966 4h-2.931zm1.109 3l2-2H8.07l-.73.485c-1 .659-1.28.866-1.332 1.515z" />
                    <path fill="none" d="M0 0h24v24H0z" />
                  </svg>
                ) : (
                  <svg
                    // onClick={pinMessage}
                    onClick={() => setIsPinCaption(true)}
                    width="23"
                    height="23"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="#b4b4b4"
                    style={{ cursor: "pointer" }}
                  >
                    <path d="M4 2h7v.278c0 .406-.086.778-.258 1.117-.172.339-.42.63-.742.875v2.86c.307.145.583.328.828.546.245.219.456.464.633.735.177.27.31.565.398.882.089.318.136.646.141.985v.5H8V14l-.5 1-.5-1v-3.222H3v-.5c0-.339.047-.664.14-.977.094-.312.227-.607.4-.883A3.404 3.404 0 0 1 5 7.13V4.27a2.561 2.561 0 0 1-.734-.875A2.505 2.505 0 0 1 4 2.278V2zm1.086.778c.042.125.094.232.156.32a1.494 1.494 0 0 0 .461.43L6 3.715v4.102l-.336.117c-.411.146-.76.383-1.047.711C4.331 8.973 4.09 9.573 4 10h7c-.088-.427-.33-1.027-.617-1.355a2.456 2.456 0 0 0-1.047-.71L9 7.816V3.715l.297-.18c.094-.057.177-.122.25-.195a2.28 2.28 0 0 0 .21-.242.968.968 0 0 0 .157-.32H5.086z" />
                  </svg>
                )}
                <DeleteOutlinedIcon
                  onClick={deleteMessage}
                  color="#fff"
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div>
                <CloseOutlinedIcon
                  onClick={() => setSelectedMessage("")}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          )}
        </div>
      )}
      {pinMessages.length !== 0 && (
        <div
          className="chat__header pin_header"
          style={{ cursor: "pointer" }}
          onClick={() => {
            scrollToMessage(pinMessages[pinMessageIndex]?.id);
            changePinMessage();
          }}
        >
          <div className="pin_divider"></div>
          {/* <hr width="1" size="500" style="0 auto" /> */}
          {pinMessages[pinMessageIndex]?.photo && (
            <img
              className="pin_photo"
              src={pinMessages[pinMessageIndex].photo}
              alt=""
            />
          )}
          <div className="pin_container">
            <div className="pin_name">
              <svg
                onClick={pinMessage}
                width="23"
                height="23"
                viewBox="0 0 16 16"
                xmlns="http://www.w3.org/2000/svg"
                fill="#b4b4b4"
                style={{ cursor: "pointer" }}
              >
                <path d="M4 2h7v.278c0 .406-.086.778-.258 1.117-.172.339-.42.63-.742.875v2.86c.307.145.583.328.828.546.245.219.456.464.633.735.177.27.31.565.398.882.089.318.136.646.141.985v.5H8V14l-.5 1-.5-1v-3.222H3v-.5c0-.339.047-.664.14-.977.094-.312.227-.607.4-.883A3.404 3.404 0 0 1 5 7.13V4.27a2.561 2.561 0 0 1-.734-.875A2.505 2.505 0 0 1 4 2.278V2zm1.086.778c.042.125.094.232.156.32a1.494 1.494 0 0 0 .461.43L6 3.715v4.102l-.336.117c-.411.146-.76.383-1.047.711C4.331 8.973 4.09 9.573 4 10h7c-.088-.427-.33-1.027-.617-1.355a2.456 2.456 0 0 0-1.047-.71L9 7.816V3.715l.297-.18c.094-.057.177-.122.25-.195a2.28 2.28 0 0 0 .21-.242.968.968 0 0 0 .157-.32H5.086z" />
              </svg>
              <p style={{ color: "#28D146", fontWeight: "bold" }}>
                {pinMessages[pinMessageIndex]?.pinCaption ||
                  pinMessages[pinMessageIndex]?.name}
              </p>
            </div>

            <p style={{ color: "white" }} className="pin_content">
              {pinMessages[pinMessageIndex]?.message ||
                pinMessages[pinMessageIndex]?.caption}
            </p>
          </div>
        </div>
      )}
    </>
  );
}

export default ChatHeader;
