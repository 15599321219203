import React, { useEffect, useState } from "react";
import { useStateValue } from "./StateProvider";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
//importing firebase
//importing actions
import { setUser } from "./actions/userAction";
//importing components
import Login from "./Login";
import Chat from "../src/Chat/Chat";
import { ToastContainer } from "react-toastify";
//importing material-ui
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
//importing styles
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import Admin from "./Admin";
import { useHistory } from "react-router-dom";
import AddUser from "./AddUser";
import db, { auth, firebase } from "./firebase";

function App() {
  const [{ user }, dispatch] = useStateValue();
  const history = useHistory();
  const [userData, setUserData] = useState(null);

  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   dispatch(setUser(null));
  //   setLoading(true);
  // }, [dispatch]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        setUserData(authUser);
        // dispatch(setUser(authUser));
        // setLoading(true);
      } else {
        dispatch(setUser(null));
        setLoading(true);
      }
    });

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      db.collection("users")
        .doc(userData.uid)
        .get()
        .then((doc) => {
          let data = doc.data();
          dispatch(
            setUser({
              name: data.name,
              uid: data.uid,
              role: data.role,
              email: data.email,
              password: data.password,
              photoUrl: data.photoURL,
            })
          );
          setLoading(true);
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
  }, [userData, dispatch, history]);

  return (
    <div className="app">
      {loading ? (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />

          <div className="app__body">
            <Router>
              <Switch>
                <Route exact path="/admin">
                  <Admin />
                </Route>
                <Route exact path="/adduser">
                  <AddUser />
                </Route>

                <Route exact path="/">
                  {!user ? <Login /> : <Chat isRoomExist={true} />}
                </Route>

                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Router>
          </div>
        </>
      ) : (
        <div className="app__loading">
          <div>
            <div className="app__loading_circular">
              <CircularProgress />
            </div>
            <div className="app__loading_linear">
              <LinearProgress />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
