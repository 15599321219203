import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import { useStateValue } from "./StateProvider";
import { setUser } from "./actions/userAction";
import db, { auth } from "./firebase";

function Login() {
  const [{ user }, dispatch] = useStateValue();

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const signIn = () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        var u = userCredential.user;
        db.collection("users")
          .doc(u.uid)
          .get()
          .then((doc) => {
            let data = doc.data();
            dispatch(
              setUser({
                name: data.name,
                uid: data.uid,
                role: data.role,
                email: data.email,
                password: data.password,
                photoUrl: data.photoURL,
              })
            );
            history.push("/");
          })
          .catch(function (error) {
            console.error("Error adding document: ", error);
          });
      })
      .catch((err) => setError("Invalid Login Credentials"));
  };

  return (
    <div className="login">
      <h2>Welcome back</h2>
      <input
        type="email"
        name="email"
        required
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        name="password"
        required
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      {error ? <p>{error}</p> : null}
      <button onClick={signIn}>Log In</button>
    </div>
  );
}

export default Login;
